import React from 'react'
import './Treatments.css';
import about1 from '../images/about1.png';
import about2 from '../images/about2.png';
import about3 from '../images/about3.png';

export default function Arrhythmia() {
  return (
    <div className="container">
        <div className='services'>
        <div className="row">
                <div className="col-3 col-lg-3 inforow aboutimg">
                    <img className='about1' src={about1} alt='about1'/>
                </div>
                <div className="col-12 col-lg-6 inforow">
                    <img className='about2' src={about2} alt='about2'/>
                </div>            
                <div className="col-4 col-lg-3 inforow aboutimg">
                    <img className='about3' src={about3} alt='about3'/>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-lg-3 aboutHeader physicians-sidebar">
                <h5>Services</h5>
                    <div className='sidebar-button sidebar-title'>Treatments</div>
                    <a href='/services/treatments/angina'><button className='sidebar-button sidebar-items'>Angina</button></a>
                    <a href='/services/treatments/antiplatelet'><button className='sidebar-button sidebar-items'>Antiplatelet Therapy</button></a>
                    <a href='/services/treatments/arrhythmia'><button className='sidebar-button sidebar-items selected'>Arrhythmia</button></a>
                    <a href='/services/treatments/congestive_heart_failure'><button className='sidebar-button sidebar-items'>Congestive Heart Failure</button></a>
                    <a href='/services/treatments/coronary_artery_disease'><button className='sidebar-button sidebar-items'>Coronary Artery Disease</button></a>
                    <a href='/services/treatments/hypertension_hypotension'><button className='sidebar-button sidebar-items'>Hypertension/Hypotension</button></a>
                    <a href='/services/treatments/hypercholesterolemia'><button className='sidebar-button sidebar-items'>Hypercholesterolemia</button></a>
                    <a href='/services/treatments/syncope'><button className='sidebar-button sidebar-items'>Syncope</button></a>
                    <a href='/services/treatments/varicose_vein'><button className='sidebar-button sidebar-items'>Varicose Vein</button></a>
                    <div className='sidebar-button sidebar-title'>Tests</div>
                    <a href='/services/tests/cardiac_catherization_angiogram'><button className='sidebar-button sidebar-items'>Cardiac Catherization/Angiogram</button></a>
                    <a href='/services/tests/cardiac_evaluation'><button className='sidebar-button sidebar-items'>Cardiac Evaluation</button></a>
                    <a href='https://www.youtube.com/watch?v=L5cIzWYkJc8' target="_blank" rel="noreferrer"><button className='sidebar-button sidebar-items'><svg xmlns="http://www.w3.org/2000/svg" color='red' width="25" height="20" fill="currentColor" class="bi bi-youtube" viewBox="0 1.5 16 15">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
</svg>&ensp;Carotid Duplex Scan</button></a>
                    <a href='/services/tests/echocardiogram'><button className='sidebar-button sidebar-items'>Echocardiogram</button></a>
                    <a href='/services/tests/electrocardiogram'><button className='sidebar-button sidebar-items'>Electrocardiogram &#40;EKG&#41;</button></a>
                    <a href='/services/tests/exercise_treadmill_test'><button className='sidebar-button sidebar-items'>Exercise Treadmill Test &#40;ETT/Stress Test&#41;</button></a>
                    <a href='/services/tests/holter_monitor_study'><button className='sidebar-button sidebar-items'>Holter Monitor Study</button></a>
                    <a href='https://www.youtube.com/watch?v=gfeRQDkPFko' target="_blank" rel="noreferrer"><button className='sidebar-button sidebar-items'><svg xmlns="http://www.w3.org/2000/svg" color='red' width="25" height="20" fill="currentColor" class="bi bi-youtube" viewBox="0 1.5 16 15">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
</svg>&ensp;Nuclear Stress Test</button></a>
                    <a href='/services/tests/stent'><button className='sidebar-button sidebar-items last'>Stent</button></a>
                </div>
                <div className="col-12 col-lg-9 portfolio">
                    <div className="row">
                        <div className="col-12 col-lg-12 physicianTitle">Arrhythmia</div>
                        <div className="col-12 col-lg-12">
                            <p className='treatmentP'>Most arrhythmias are considered harmless and are left untreated. Once your doctor has documented that you have an arrhythmia, they will determine whether it's abnormal or merely reflects the heart's normal processes. Your doctor will also determine whether your arrhythmia is clinically significant - that is, whether it causes symptoms or puts you at risk for more serious arrhythmias or complications in the future. If your arrhythmia is abnormal and clinically significantm your doctor will set a treatment plan.</p>
                            <p className='treatmentRedEmphises'>Treatment goals</p>
                            <ul>
                                <li>Prevent blood clots from forming to reduce stroke risk</li>
                                <li>Control your heart rate within a relatively normal range</li>
                                <li>Restore a normal heart rhythm, if possible</li>
                                <li>Treat heart disease/condition that may be causing arrhythmia</li>
                                <li>Reduce other risk factors for heart disease and stroke</li>
                            </ul>
                            <p className='treatmentRedEmphises'>Certain substances can contribute to an irregular heartbeat, including:</p>
                            <ul>
                                <li>Caffeine</li>
                                <li>Tobacco</li>
                                <li>Alcohol</li>
                                <li>Cold and cough medications</li>
                                <li>Appetite suppressants</li>
                                <li>Psyschotropic drugs &#40;used to treat certain mental illnesses&#41;</li>
                                <li>Antiarrhythmics &#40;paradoxically, the same drugs used to treat arrhythmia can also cause arrhythmia.</li>
                                <li>Your healthcare team will monitor you carefully if you're taking antiarrhythmic medication.&#41;</li>
                                <li>Beta-blockers for high blood pressure</li>
                                <li>Street drugs such as cocaine, marijuana, and "speed" or methamphetamines</li>
                            </ul>
                            <p>If you're being treated for arrhythmia and use any of these substances, be sure to discuss this with your doctor.</p>
                            <p className='treatmentRedEmphises'>Manage your risk factors</p>
                            <p>Just having an arrhythmia increases your risk of heart attack, cardiac arrest, and stroke.</p>
                            <p className='treatmentRedEmphises'>Work with a healthcare team and follow instructions on how to control other risk factors:</p>
                            <ul>
                                <li>Reduce high blood pressure</li>
                                <li>Control cholesterol levels</li>
                                <li>Lose excess weight</li>
                                <li>Eat a heart-healthy diet</li>
                                <li>Avoid tobacco smoke</li>
                                <li>Enjoy regular physical activity one day at a time</li>
                            </ul>
                            <p>Researchers continue to investigate arrhythmias, and are making progress. The best thins one can do is follow a treatment plan and take things one day at a time. Sometimes you may feel that you aren't getting the support you need. That's common, because other don't easily see your symptoms. It may be difficult for them to understand that you could be struggling to function normally. Help others understand by educating them about your condition. When needed, ask for support in following your treatment program.</p>
                            <p>Talk to a doctor about your care or any question you have.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
