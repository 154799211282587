import React from 'react'
import './Physicians.css';
import dohee1 from '../images/dohee1.jpg';
import about2 from '../images/about2.png';
import dohee3 from '../images/dohee2.jpg';
import DoheeKimProfile from '../images/Ali_Golshan.png';

export default function Ali_Golshan() {
  return (
    <div className="container">
        <div className='physicians'>
            <div className="row">
            <div className="col-3 col-lg-3 inforow aboutimg">
                    <img className='dohee1' src={dohee1} alt='about1'/>
                </div>
                <div className="col-12 col-lg-6 inforow">
                    <img className='about2' src={about2} alt='about2'/>
                </div>            
                <div className="col-4 col-lg-3 inforow aboutimg">
                    <img className='dohee3' src={dohee3} alt='about3'/>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-lg-3 aboutHeader physicians-sidebar">
                    <h5>Doctors/Physicians</h5>
                    <a href='/physicians/stevep'><button className='sidebar-button'>Cardiologist</button></a>
                    <a href='/physicians/doheek'><button className='sidebar-button'>Nuclear Physician</button></a>
                    <a href='/physicians/alig'><button className='sidebar-button selected'>Interventional Radiologist</button></a>
                </div>
                <div className="col-12 col-lg-9 portfolio">
                    <div className="row">
                        <div className="col-12 col-lg-12 physicianTitle">Ali Golshan, M.D. ,Interventional Radiologist</div>
                        <div className="col-12 col-lg-4 photorow">
                            <img src={DoheeKimProfile} alt="DoheeKimProfilePic" width={300}></img>
                        </div>
                        <div className="col-12 col-lg-8 aboutP">
                            <p>Dr. Golshan received his BS from UCLA, where he graduated valedictorian of his major, summa cum laude and Phi Beta Kappa. He enrolled in the dual MD/MBA program at UCLA and concurrently earned his MBA from the Anderson School of Business, where he was awarded the Leo G. Rigler Award &#40;given to one graduating UCLA medical student annually&#41;. He subsequently completed a radiology residency at UCLA, where he conducted research and presented his findings at RSNA &#40;Radiological Society of North America&#41;. He also conducted research in interventional neuroradiology leading to multiple abstracts and publications. He then completed a fellowship in interventional radiology at Johns Hopkins University, receiving exemplary training in all aspects of image guided intervention.</p>
                            <p>Dr. Golshan specializes in the treatment of uterine fibroids &#40;UFE&#41;, liver tumors/metastasis, peripheral arterial disease &#40;PAD&#41;, varicose veins, and dialysis access management.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
