import React from 'react'
import './Accessibility.css';
import about1 from '../images/about1.png';
import about2 from '../images/about2.png';
import about3 from '../images/about3.png';

export default function Accessibility() {
  return (
    <div className="container">
        <div className='about'>
            <div className="row">
                <div className="col-3 col-lg-3 inforow aboutimg">
                    <img className='about1' src={about1} alt='about1'/>
                </div>
                <div className="col-12 col-lg-6 inforow">
                    <img className='about2' src={about2} alt='about2'/>
                </div>            
                <div className="col-4 col-lg-3 inforow aboutimg">
                    <img className='about3' src={about3} alt='about3'/>
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-lg-3 aboutHeader">
                    <h5>Accessibility</h5>
                </div>
                <div className="col-12 col-lg-9 aboutrow">
                    <div className='accessibilityP'>
                        <p>
                            Wilshire Cardiology Group is strongly commited to accessibility and making our website accessible for all Californians, especially for those with disabilities. Examples of disabilities include vision loss, hearing loss, limited manual dexterity, and cognitive disabilities.
                        </p>
                        <h3>
                            Accesible Features
                        </h3>
                        <p>
                            Our website follows the WCAG 2.0 AA Guidelines. Below you will find some technology solutions to make our website easy to navigate and accessible.
                        </p>
                        <h4>
                            Keyboard commnads for:
                        </h4>
                        <p>
                            <ul>
                                <li><a href='https://support.microsoft.com/en-us/help/13805/windows-keyboard-shortcuts-in-apps' target="_blank" rel="noreferrer">Edge Browser</a></li>
                                <li><a href='https://support.microsoft.com/en-us/help/15357/windows-internet-explorer-11-keyboard-shortcuts' target="_blank" rel="noreferrer">Internet Explorer</a></li>
                                <li><a href='https://support.mozilla.org/en-US/kb/keyboard-shortcuts-perform-firefox-tasks-quickly' target="_blank" rel="noreferrer">Firefox</a></li>
                                <li><a href='https://support.google.com/chrome/answer/157179?hl=en' target="_blank" rel="noreferrer">Chrome</a></li>
                                <li><a href='https://support.apple.com/guide/safari/keyboard-and-other-shortcuts-cpsh003/mac' target="_blank" rel="noreferrer">Safari</a></li>
                            </ul>
                        </p>
                        <p>
                            If you have difficulty accessing any material on this site, please contact us at <a href='mailto:info@wilshirecardiology.com'>info@wilshirecardiology.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
