import React from 'react'
import './Sitemap.css';

export default function NotFound() {
  return (
    <div className="container">
        <p style={{fontWeight: "bold", textAlign: "center", fontSize: 50, marginTop: "20vh"}}>Not Found</p>
    </div>
  )
}
